var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as amplitude from '@amplitude/analytics-browser';
import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { isMobile } from '../services/mobile-bridge/mobile-bridge';
import RenewAuthTokenService from '../services/renew-auth-token/renew-auth-token';
import ServiceHandler from '../services/service-handler';
export var RenewAuthTokenContext = React.createContext({
    refreshToken: null,
});
export var RenewAuthTokenContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(''), refreshToken = _b[0], setRefreshToken = _b[1];
    var authService = ServiceHandler.AuthenticationService;
    var _c = useState(true), returned = _c[0], setReturned = _c[1];
    // const history = useHistory();
    useEffect(function () {
        if (authService.getIsAuthenticated()) {
            setRefreshToken(authService.getCatBundle().refresh_token);
        }
    }, []);
    // useEffect(() => {
    //   const checkIfExpired = () => {
    //     if (authService.getCatBundle() && new Date().getTime() / 1000 > JSON.parse(atob(authService.getCatBundle().access_token.split('.')[1])).exp) {
    //       if (isMobile()) {
    //         setReturned(false);
    //         renewAuthToken();
    //       }
    //     }
    //   };
    //   checkIfExpired();
    //   history.listen(checkIfExpired);
    // }, [history]);
    //move to routes FPR
    var renewAuthToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var renewAuthTokenService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setReturned(false);
                    renewAuthTokenService = new RenewAuthTokenService();
                    amplitude.logEvent('rewards renew auth token');
                    return [4 /*yield*/, renewAuthTokenService.requestNewAuthToken()];
                case 1:
                    _a.sent();
                    setRefreshToken(authService.getCatBundle().refresh_token);
                    setReturned(true);
                    return [2 /*return*/];
            }
        });
    }); };
    return returned && React.createElement(RenewAuthTokenContext.Provider, { value: [{ refreshToken: refreshToken, renewAuthToken: renewAuthToken }] }, children);
};
