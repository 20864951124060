var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as amplitude from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../services/mobile-bridge/mobile-bridge';
import ServiceHandler from '../services/service-handler';
export var useAmplitude = function () {
    var location = useLocation();
    var getAmplitudeScreenTitle = function () {
        return (location === null || location === void 0 ? void 0 : location.pathname.replace(/(claim-points)\/.+/, '$1').replace(/^rewards-/, '').replace(/\W+/g, ' ').replace(/(lar|fpr) sd campaigns /, 'campaign ').trim().toLowerCase());
    };
    var logAmplitudeEvent = function (name, params) {
        if (name === void 0) { name = 'unknown'; }
        if (params === void 0) { params = {}; }
        console.log("log event");
        amplitude.track("rewards ".concat(name).toLowerCase().replace(/\W+/g, ' '), __assign(__assign({}, params), { screenName: getAmplitudeScreenTitle(), loyaltyExperience: "".concat(ServiceHandler.ConfigService.BRAND_ID, "_").concat(ServiceHandler.ConfigService.PROGRAM_COUNTRY), loyaltyMobile: isMobile() }));
    };
    return { logAmplitudeEvent: logAmplitudeEvent, getAmplitudeScreenTitle: getAmplitudeScreenTitle };
};
