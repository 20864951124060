var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import ServiceHandler from '../../../services/service-handler';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';
import { Route } from 'react-router-dom';
import { useAmplitude } from '../../../hooks/use-amplitude';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
export var AuthorizedRoute = function (_a) {
    var render = _a.render, Context = _a.context, rest = __rest(_a, ["render", "context"]);
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var _b = useState(null), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var authService = ServiceHandler.AuthenticationService;
    useEffect(function () {
        if (!isMobile()) {
            authService.onIsAuthenticated().then(function (isAuthenticated) {
                setIsAuthenticated(isAuthenticated);
                if (!isAuthenticated) {
                    // Ternary is for fix for dev redirect issue
                    window['fma'].model.config.redirectUrl = window.location.href;
                    logAmplitudeEvent('authenticate');
                    authService.login();
                }
            });
        }
    }, [authService]);
    if (Context) {
        return (React.createElement(Route, __assign({}, rest), isAuthenticated === true || isMobile() ? (React.createElement(Context, null, render())) : (React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement(ActivityIndicator, null)))));
    }
    else {
        return (React.createElement(Route, __assign({}, rest), isAuthenticated === true || isMobile() ? (render()) : (React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement(ActivityIndicator, null)))));
    }
};
