/* istanbul ignore file */
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useAmplitude } from '../hooks/use-amplitude';
export var APIHelperContext = React.createContext({
    hasError: null,
    handleError: null,
    handleFatalError: null,
});
export var APIHelperContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), hasError = _b[0], setHasError = _b[1];
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var history = useHistory();
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var handleError = function (error, serviceCode, service, msg) {
        var _a, _b, _c, _d, _e;
        var message = msg || ((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message) || (error === null || error === void 0 ? void 0 : error.toString()) || 'none';
        if (message.includes('globalErrorCode'))
            message = JSON.parse(message).globalErrorCode.replace(/_/g, ' ');
        logAmplitudeEvent("".concat(service, " error"), {
            srvc: serviceCode,
            msg: message,
            data: JSON.stringify(((_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) || 'none'),
            status: ((_e = error === null || error === void 0 ? void 0 : error.response) === null || _e === void 0 ? void 0 : _e.status) || 'none',
        });
        if (message)
            setErrorMessage(message);
        setHasError(serviceCode);
    };
    var handleFatalError = function (error, serviceCode, service, msg) {
        if (window.location.href.includes('/maintenance-break'))
            return;
        handleError(error, serviceCode, service, msg);
        history === null || history === void 0 ? void 0 : history.push('/maintenance-break');
    };
    return (React.createElement(APIHelperContext.Provider, { value: [
            { hasError: hasError, handleError: handleError, handleFatalError: handleFatalError, errorMessage: errorMessage, setErrorMessage: setErrorMessage },
            { hasError: hasError, handleError: handleFatalError, handleFatalError: handleFatalError, errorMessage: errorMessage, setErrorMessage: setErrorMessage },
        ] }, children));
};
