import React, { useState, useEffect } from 'react';
import { useContent } from '../../../hooks/use-content';
// Load views for different brands
//////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
import '../../../views-common/points-expiration/fpr-points-expire.scss'; // eslint-disable-line no-redeclare
//////////
import ServiceHandler from '../../../services/service-handler';
var GenericBanner = function () {
    var GENERIC_BANNER = 'genericBannerOpen';
    var authService = ServiceHandler.AuthenticationService;
    var getValueByTitle = useContent('banner/generic-banner')[0].getValueByTitle;
    var _a = useState(false), show = _a[0], setShow = _a[1];
    var setShowDialog = function () {
        setShow(false);
        sessionStorage.setItem(GENERIC_BANNER, 'true');
    };
    useEffect(function () {
        if (authService.getIsAuthenticated()) {
            if (sessionStorage.getItem(GENERIC_BANNER) !== 'true') {
                setShow(false);
            }
        }
        else {
            setShow(false);
        }
    }, [authService]);
    {
        return show ? (React.createElement("div", { className: 'pointsExpireContainer' },
            React.createElement("div", { className: 'expireTextContainer' },
                React.createElement("div", { className: 'footnoteIcon' }),
                React.createElement("div", { className: 'expireText' }, getValueByTitle('disclaimer-text'))),
            React.createElement("button", { onClick: function () { setShowDialog(); }, className: 'closeIcon' },
                React.createElement("div", null)))) : (React.createElement(React.Fragment, null));
    }
};
export default GenericBanner;
