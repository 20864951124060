var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect } from 'react';
import { sanatizedLocationSearch } from '../routes-fpr';
import contentService from '../services/content-service/content-service';
import { getTitleFromContent } from '../services/content-service/content-service-util';
import seoService from '../services/seo-service/seo-service';
import { useAmplitude } from './use-amplitude';
export var toHtml = function (value) {
    var pathname = window.location.pathname[window.location.pathname.length - 1] == '/'
        ? window.location.pathname.slice(0, window.location.pathname.length - 1)
        : window.location.pathname;
    return value
        .replace(/<\/?p>/g, '')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/<a href='rewards(.*?)'>/g, "<a href='".concat(window.location.origin).concat(pathname, "$1").concat(sanatizedLocationSearch(), "'>"))
        .replace(/<a href='fordpass\/fordpass-rewards(.*?)'>/g, "<a href='".concat(window.location.origin).concat(pathname.slice(0, pathname.lastIndexOf('/')), "$1").concat(sanatizedLocationSearch(), "'>"));
};
export function useContent(name) {
    var _this = this;
    var _a = useState(null), content = _a[0], setContent = _a[1];
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    useEffect(function () {
        var loadContent = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, error_1;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        _a = setContent;
                        return [4 /*yield*/, contentService.getContent(name)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _c.sent();
                        if ((_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.status)
                            logAmplitudeEvent('useContent error', {
                                name: name,
                                error: error_1.toString(),
                            });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadContent();
    }, [name]);
    function getValueByTitle(title) {
        if (content) {
            return getTitleFromContent(content, title);
        }
        else {
            return '';
        }
    }
    function getHtmlByTitle(title, modify) {
        return modify ? modify(toHtml(getValueByTitle(title))) : toHtml(getValueByTitle(title));
    }
    function getCtaProps(title) {
        return { label: getValueByTitle(title), id: title };
    }
    seoService.set({
        title: getValueByTitle('page-title'),
        metaDescription: getValueByTitle('meta-description'),
    });
    return [{ content: content, getValueByTitle: getValueByTitle, getCtaProps: getCtaProps, getHtmlByTitle: getHtmlByTitle }, getValueByTitle];
}
export function useExperienceContent(name, category, componentName) {
    var _this = this;
    var _a = useState(null), content = _a[0], setContent = _a[1];
    useEffect(function () {
        if (name) {
            var loadContent = function () { return __awaiter(_this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, contentService.getExperience(name)];
                        case 1:
                            data = _a.sent();
                            setContent(data[':items'].root[':items'][category][componentName]);
                            return [2 /*return*/];
                    }
                });
            }); };
            loadContent();
        }
    }, [name]);
    function getFragments() {
        return content || [];
    }
    return [content, getFragments];
}
export function useConfig(name) {
    var _this = this;
    var _a = useState(null), config = _a[0], setConfig = _a[1];
    useEffect(function () {
        if (name) {
            var loadConfig = function () { return __awaiter(_this, void 0, void 0, function () {
                var config;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, contentService.getConfig(name).catch(function () {
                                return null;
                            })];
                        case 1:
                            config = _a.sent();
                            setConfig(config ? config.elements[0].value : '{}');
                            return [2 /*return*/];
                    }
                });
            }); };
            loadConfig();
        }
    }, [name]);
    return [config];
}
